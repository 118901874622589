import React, { FC, useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import felicaRead from '../utils/felica';
import { getCardID } from '../services/getCardId';
import { postReportAndCount } from '../services/postReportAndCount';

interface ReadState {
  branch: string,
  branch_id: string,
}

const ReadPage: FC = () => {
  const [cardNumber, setCardNumber] = useState<string>('');
  const [cardSystemCode, setSystemCode] = useState<number>(0);
  const [navigateFlag, setNavigateFlag] = useState(false);
  const [errorCode, setErrorCode] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<number>(0);

  const read_image = '/material/read_image.png';
  const soundFile = '/material/m1.mp3';

  const navigate = useNavigate();
  const location = useLocation();
  const readstate = location.state as ReadState;

  useEffect(() => {
    if(navigateFlag) {
      navigate(`/${readstate.branch}/tb`);
    }
  }, [navigate, readstate.branch, navigateFlag]);

  const handleBack = () => {
    navigate(`/${readstate.branch}/tb`);
  }

  useEffect(() => {
    const report_action = async (card_id: string) => {
      const report_response = await postReportAndCount(card_id, readstate.branch_id);

      clearInterval(intervalId)
      if(report_response.error?.response?.status === 401) {
        clearInterval(intervalId)
        setErrorCode(3);
        return;
      }
      if(report_response.error_code) {
        clearInterval(intervalId)
        setErrorCode(1);
        return;
      }
      if(JSON.parse(report_response.data.result).error) {
        clearInterval(intervalId)
        setErrorCode(1);
        return;
      }
      if(report_response) {
        let countThisYear = JSON.parse(report_response.data.result).info.count_this_year;
        let countTotal = JSON.parse(report_response.data.result).info.count_total;
        navigate(`/result`, {state: { count_this_year: countThisYear, count_total: countTotal, branch: readstate.branch }, replace: true});
      }
    }

    if(cardNumber.length === 0 || cardSystemCode === 2) return
    (async() => {
      const audioElement = new Audio(soundFile);
      audioElement.play();

      if(cardSystemCode === 0) { //信徒カードの場合
        const getcard_response = await getCardID(cardNumber);
        if(getcard_response.error_code) {
          setErrorCode(1);
          return;
        }

        if(JSON.parse(getcard_response.data.results).error !== null) {
          if(JSON.parse(getcard_response.data.results).error[0].code === 410) {
            setErrorCode(4);
            return;
          }
          setErrorCode(1);
          return;
        }
        const card_id = JSON.parse(getcard_response.data.results).user_id
        await report_action(card_id)
      }
      else { //職員カードの場合
        let card_id = cardNumber.replace(/\0/g, '');
        await report_action(card_id)
      }
    })()
  }, [navigate, cardNumber, cardSystemCode, readstate.branch, readstate.branch_id, intervalId]);

  useEffect(() => {
    const felicaFunc = async () => {
      let felica_intervalId = 0;
      felica_intervalId = Number(
        setInterval(async () => {
          try {
          const resultData = await felicaRead(felica_intervalId);
          setCardNumber(resultData.cardNumber);
          setSystemCode(resultData.systemCode);
          } catch(err: any) {
            clearInterval(felica_intervalId)
            setErrorCode(1);
            return;
          }
        }, 700),
      );
      setIntervalId(felica_intervalId)
    }
    felicaFunc();
    setTimeout(() => {
      setNavigateFlag(true);
    }, 10000);
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(intervalId)
    };
  }, [intervalId]);

  useEffect(() => {
    if(errorCode !== 0) {
        navigate(`/error`, {state: { branch: readstate.branch, error_code: errorCode }, replace: true});
    }
  }, [navigate, errorCode, readstate.branch]);

  const blockBrowserBack = useCallback(() => {
    window.history.go(3)
  }, [])

  useEffect(() => {
      window.history.pushState(null, '', window.location.href)

      window.addEventListener('popstate', blockBrowserBack)

      return () => {
          window.removeEventListener('popstate', blockBrowserBack)
      }
  }, [blockBrowserBack])

  return (
    <div className="flex flex-col items-center justify-center w-1/2" style={{ backgroundColor: '#fdf2d1', height: '70vh' }}>
      <button className="relative bg-white rounded-md py-2 px-2 font-bold border border-black text-3xl" onClick={handleBack} style={{ top: "-0px", left: "-550px" }} >戻る</button>
      <img src={read_image} alt="" className="" />
      <h1 className="text-2xl font-bold" style={{ textShadow: "3px 3px 3px white", marginTop: '-50px' }}>カードリーダーにしっかりとカードをかざしてください</h1>
    </div>
  );
};

export default ReadPage;
